import { Rate } from '@pretto/website/src/components/Rates/RatesTable/types/Rate.type'
import { type RowMediaContainerProps } from '@pretto/website/src/templates/blocs/rows/rowMedia/RowMediaContainer'
import { type HeroPictureRateContainerProps } from '@pretto/website/src/templates/home/rows/heroPictureRate/HeroPictureRateContainer'
import { type RowBanksContainerProps } from '@pretto/website/src/templates/home/rows/rowBanks/RowBanksContainer'
import { type RowBannerProps } from '@pretto/website/src/templates/home/rows/rowBanner/components/RowBanner/RowBanner'
import { type RowBrokersProps } from '@pretto/website/src/templates/home/rows/rowBrokers/RowBrokersContainer'
import { type RowFaqProps } from '@pretto/website/src/templates/home/rows/rowFaq/components/RowFaq'
import { type RowPromisesProps } from '@pretto/website/src/templates/home/rows/rowPromises/components/RowPromises/RowPromises'
import { type RowReviewsContainerProps } from '@pretto/website/src/templates/home/rows/rowReviews/RowreviewsContainer'
import { type RowToolsProps } from '@pretto/website/src/templates/home/rows/rowTools/components/RowTools'

import loadable from '@loadable/component'
import Cookies from 'js-cookie'
import { useEffect, useMemo } from 'react'

const HeroPictureRateContainer = loadable(
  () => import('@pretto/website/src/templates/home/rows/heroPictureRate/HeroPictureRateContainer'),
  {
    resolveComponent: components => components.HeroPictureRateContainer,
  }
)

const RowBanner = loadable(
  () => import('@pretto/website/src/templates/home/rows/rowBanner/components/RowBanner/RowBanner'),
  {
    resolveComponent: components => components.RowBanner,
  }
)

const RowPromises = loadable(
  () => import('@pretto/website/src/templates/home/rows/rowPromises/components/RowPromises/RowPromises'),
  {
    resolveComponent: components => components.RowPromises,
  }
)

const RowTools = loadable(() => import('@pretto/website/src/templates/home/rows/rowTools/components/RowTools'), {
  resolveComponent: components => components.RowTools,
})

const RowBrokers = loadable(() => import('@pretto/website/src/templates/home/rows/rowBrokers/RowBrokersContainer'), {
  resolveComponent: components => components.RowBrokersContainer,
})

const RowBanks = loadable(() => import('@pretto/website/src/templates/home/rows/rowBanks/RowBanksContainer'), {
  resolveComponent: components => components.RowBanksContainer,
})

const RowReviews = loadable(() => import('@pretto/website/src/templates/home/rows/rowReviews/RowreviewsContainer'), {
  resolveComponent: components => components.RowReviewsContainer,
})

const RowMedia = loadable(() => import('@pretto/website/src/templates/blocs/rows/rowMedia/RowMediaContainer'), {
  resolveComponent: components => components.RowMediaContainer,
})

const RowFaq = loadable(() => import('@pretto/website/src/templates/home/rows/rowFaq/components/RowFaq'), {
  resolveComponent: components => components.RowFaq,
})

type HomeHerosProps = Omit<HeroPictureRateContainerProps, 'title'> & {
  __typename: 'WpTemplate_Home_HomeRows_HomeHeros_HomeHerosPictureRate'
}

type HomeRowsProps =
  | (RowBannerProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsBanner' })
  | (RowPromisesProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsPromises' })
  | (RowToolsProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsTools' })
  | (RowBrokersProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsBrokers' })
  | (RowBanksContainerProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsBanks' })
  | (RowReviewsContainerProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsReviews' })
  | (RowMediaContainerProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsMedia' })
  | (RowFaqProps & { __typename: 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsFaq' })

type HomeBlocsProps = {
  articlesProps: object[]
  rates: Rate[]
  data: {
    title: string
    template: {
      home_rows: {
        homeHeros: HomeHerosProps[]
        homeRows: HomeRowsProps[]
      }
    }
  }
}

export const HomeBlocs = ({
  articlesProps,
  rates,
  data: {
    title,
    template: {
      home_rows: { homeHeros, homeRows },
    },
  },
}: HomeBlocsProps) => {
  useEffect(() => {
    const homePath = window.location.pathname
    Cookies.set('homePath', homePath)
  }, [])

  const heroComponent = useMemo(() => {
    if (homeHeros?.[0]) {
      const { __typename, ...rowProps } = homeHeros[0]

      switch (__typename) {
        case 'WpTemplate_Home_HomeRows_HomeHeros_HomeHerosPictureRate':
          return <HeroPictureRateContainer title={title} rates={rates} {...rowProps} />

        default:
          return null
      }
    }
  }, [])

  const rowsComponents = useMemo(() => {
    return homeRows.map(({ __typename, ...moduleProps }, index) => {
      const rowProps = { ...moduleProps, key: index }

      switch (__typename) {
        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsBanner':
          return <RowBanner {...rowProps} />

        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsPromises':
          return <RowPromises {...rowProps} />

        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsTools':
          return <RowTools {...rowProps} />

        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsBrokers':
          return <RowBrokers {...rowProps} />

        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsBanks':
          return <RowBanks {...rowProps} />

        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsReviews':
          return <RowReviews {...rowProps} />

        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsMedia':
          return <RowMedia {...rowProps} articlesProps={articlesProps} />

        case 'WpTemplate_Home_HomeRows_HomeRows_HomeRowsFaq':
          return <RowFaq {...rowProps} />

        default:
          return null
      }
    })
  }, [])

  return [heroComponent, ...rowsComponents]
}
